<template>
  <v-container>
    <v-breadcrumbs :items="breadcrumbs" divider=">"></v-breadcrumbs>
    <h1 class="headline pb-4 mx-lg-auto">Settings</h1>
    <v-data-table :headers="headers" :items="items" @click:row="editItem" disable-pagination hide-default-footer>
    </v-data-table>
  </v-container>
</template>

<script>
// import store from '@/store/index';

export default {
  name: 'Settings',
  methods: {
    editItem(item) {
      if (item.path) {
        this.$router.push({ path: `${item.path}` });
      } else {
        this.$router.push({ path: `/settings/${item.id}` });
      }
    }
  },
  data: () => ({
    headers: [{ text: 'Title', value: 'title' }],
    items: [
      {
        id: 1,
        title: 'Age Level'
      },
      {
        id: 2,
        title: 'Airline'
      },
      {
        id: 3,
        title: 'Gender'
      },
      {
        id: 4,
        title: 'Group Type'
      },
      {
        id: 10,
        title: 'Flight Type'
      },
      {
        id: 12,
        title: 'Organisation Type'
      },
      {
        id: 9,
        title: 'Family Member Relationship'
      },
      {
        id: 6,
        title: 'Paperwork Group'
      },
      {
        id: 13,
        title: 'Paperwork Set',
        path: 'setting-paperwork-set-list'
      },

      {
        id: 5,
        title: 'Paperwork Template'
      },

      {
        id: 7,
        title: 'Staff Type'
      },
      {
        id: 8,
        title: 'Staff Role'
      },
      {
        id: 14,
        title: 'Two Factor Authentication',
        path: '/two-factor-authentication'
      }
    ],
    breadcrumbs: [
      {
        text: 'Dashboard',
        exact: true,
        to: '/dashboard'
      },
      {
        text: 'Settings',
        exact: true,
        to: '/settings'
      }
    ]
  })
};
</script>
